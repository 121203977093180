import store from '@/store';
import {
    mdiAccountCheckOutline,
    mdiAccountOutline,
    mdiAccountPlusOutline,
    mdiAccountRemoveOutline,
    mdiCogOutline,
    mdiDatabaseOutline,
    mdiDnsOutline,
    mdiPencilOutline,
} from '@mdi/js';
import { ref, watch } from '@vue/composition-api';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import en from '@/plugins/i18n/locales/en';

export default function useMedicationsList() {
    const medicationListTable = ref([]);

    const tableColumns = [
        { text: i18n.t('Name'), value: 'name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Description'), value: 'description', class: 'text-uppercase' },
        { text: i18n.t('Users'), value: 'users', class: 'text-uppercase' },
        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const searchQuery = ref('');
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const totalMedicationListTable = ref(0);
    const loading = ref(false);
    const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
    });
    const medicationTotalLocal = ref([]);
    const selectedRows = ref([]);
    const oldSearch = ref('');
    const oldUserFilter = ref(' ');

    // fetch data
    const fetchMedicationList = () => {
        let verifyQuery = store.getters['app-medication/getMedicationQuery'];
        let isDeleteMedication = store.getters['app-medication/getIsDeleteMedication'];
        store
            .dispatch('app-medication/fetchMedicationList', {})
            .then((response) => {
                if (isDeleteMedication) {
                    medicationListTable.value = [];
                    medicationListTable.value.push(...response.data.data);
                    store.commit('app-medication/setIsDeleteMedication', false);
                } else if (
                    oldSearch.value == verifyQuery.filters.$or[0].name.$containsi
                    // &&
                    // oldUserFilter.value === verifyQuery.filters.users.id.$containsi
                ) {
                    medicationListTable.value = [];
                    medicationListTable.value.push(...response.data.data);
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].name.$containsi;
                    // oldUserFilter.value = verifyQuery.filters.users.id.$containsi;
                    medicationListTable.value = [];
                    medicationListTable.value.push(...response.data.data);
                }
                totalMedicationListTable.value = response.data.meta.pagination.total;

                // medicationTotalLocal.value = medicationTotal;
                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    watch([searchQuery, roleFilter, planFilter, statusFilter, options], () => {
        loading.value = true;
        selectedRows.value = [];
        fetchMedicationList();
    });

    const resolveMedicationPicture = (pictures) => {
        if (_.get(pictures, '[0].formats.thumbnail.url')) return _.get(pictures, '[0].formats.thumbnail.url');

        if (_.get(pictures, 'url')) return _.get(pictures, 'url');
        return null;
    };
    const resolveMedicationPictureMax = (pictures) => {
        if (_.get(pictures, '[0].formats.large.url')) return _.get(pictures, '[0].formats.large.url');

        if (_.get(pictures, '[0].url')) return _.get(pictures, '[0].url');
        return null;
    };
    const resolveMedicationRoleVariant = (role) => {
        if (role === 'subscriber') return 'primary';
        if (role === 'author') return 'warning';
        if (role === 'maintainer') return 'success';
        if (role === 'editor') return 'info';
        if (role === 'admin') return 'error';

        return 'primary';
    };

    const resolveMedicationRoleIcon = (role) => {
        if (role === 'subscriber') return mdiAccountOutline;
        if (role === 'author') return mdiCogOutline;
        if (role === 'maintainer') return mdiDatabaseOutline;
        if (role === 'editor') return mdiPencilOutline;
        if (role === 'admin') return mdiDnsOutline;

        return mdiAccountOutline;
    };

    const resolveMedicationstatusVariant = (status) => {
        if (status === 'pending') return 'warning';
        if (status === 'active') return 'success';
        if (status === 'inactive') return 'secondary';

        return 'primary';
    };

    const resolveMedicationTotalIcon = (total) => {
        if (total === 'Total Medications') return { icon: mdiAccountOutline, color: 'primary' };
        if (total === 'Paid Medications') return { icon: mdiAccountPlusOutline, color: 'error' };
        if (total === 'Active Medications') return { icon: mdiAccountCheckOutline, color: 'success' };
        if (total === 'Pending Medications') return { icon: mdiAccountRemoveOutline, color: 'warning' };

        return { icon: mdiAccountOutline, color: 'primary' };
    };

    const limitMedicationText = (medications) => {
        if (medications.length > 1) {
            return ' + ' + (medications.length - 1);
        }
    };

    return {
        medicationListTable,
        tableColumns,
        searchQuery,
        roleFilter,
        planFilter,
        statusFilter,
        totalMedicationListTable,
        loading,
        options,
        medicationTotalLocal,
        selectedRows,
        fetchMedicationList,
        resolveMedicationPicture,
        resolveMedicationPictureMax,
        resolveMedicationRoleVariant,
        resolveMedicationRoleIcon,
        resolveMedicationstatusVariant,
        limitMedicationText,
    };
}
